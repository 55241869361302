<template>
    <div>
        <a-form :form="form" @submit="handleSubmit">
            <a-row type="flex" :gutter="24">
                <a-col :span="24" :lg="24" class="mb-24">
                    <a-card :bordered="false" class="header-solid h-full"
                        :bodyStyle="{ paddingTop: 0, paddingBottom: '40px' }">
                        <template #title>
                            <h5 class="mb-0">Places to stay answer details</h5>
                        </template>
                        <a-row :gutter="[12]">
                            <a-col :span="6">
                                <a-form-item class="mb-10" label="Title" :colon="false">
                                    <a-input v-model="title" placeholder="eg. Title" />
                                </a-form-item>
                            </a-col>
                        </a-row>
                    </a-card>
                </a-col>
                <a-button type="primary" html-type="submit"> Submit </a-button>
            </a-row>
        </a-form>
    </div>
</template>

<script>
import placesToStayAPI from '../../api/placesToStay';
import router from '../../router';

export default {
    data() {
        return {
            title: '',
            placesToStayEditData: null,
            isEdit: false,
            form: this.$form.createForm(this, { title: "editPlacesToStay" }),
        };
    },
    methods: {
        handleSubmit(e) {
            e.preventDefault();
            this.form.validateFields((err, placesToStay) => {
                if (!err) {
                    placesToStay.title = this.title;
                    if (!this.isEdit) {
                        placesToStayAPI.createPlacesToStay(placesToStay).then((response) => {
                            if (response.status == 200) {
                                router.push('/places-to-stay-answers/places-to-stay-answers-list')
                            }
                        }).catch((error) => {
                            console.log(error);
                            router.push('/places-to-stay-answers/places-to-stay-answers-list');
                        })
                    } else {
                        placesToStayAPI.updatePlacesToStay(this.placesToStayEditData.data.id, placesToStay).then((response) => {
                            if (response.status == 200) {
                                router.push('/places-to-stay-answers/places-to-stay-answers-list')
                            }
                        }).catch((error) => {
                            console.log(error);
                            router.push('/places-to-stay-answers/places-to-stay-answers-list');
                        })
                    }
                }
            });
        },
        async getPlacesToStayById(id) {
            return placesToStayAPI.getPlacesToStay(id);
        }
    },
    async mounted() {
            if (this.$route.params.id !== undefined) {
            this.placesToStayEditData = await this.getPlacesToStayById(this.$route.params.id); // get placesToStay title by id to edit it
            this.isEdit = true;
            if (this.placesToStayEditData.code == "SUCCESS") {
                this.title = this.placesToStayEditData.data.title;
            }
        }
    },
};
</script>
